<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('partnersSettlements.form.addSalesPayment')"
          :description="
            $t('partnersSettlements.form.FromHereYouCanCreateaNewSalesPayment')
          "
          v-if="$route.name == 'partnersSettlementsCreate'"
        />
        <addHeader
          :title="$t('partnersSettlements.form.SalesPaymentadjustment')"
          :description="
            $t('partnersSettlements.form.FromHereYouCanModifyTheSalesPaymentInformation')
          "
          v-if="$route.name == 'partnersSettlementsEdit'"
        />

        <div class="mb-4 row">
          <connectionInput
            :title="$t('partnersSettlements.form.NumberingTheProcess')"
            :group="$t('partnersSettlements.form.Groups')"
            v-model="item.invoice_group"
            :disabled=" $user.admin || $user.role.sales_payments_edit_item ? false : true"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'partnersSettlementsCreate'"
            :hasErorr="errors.invoice_group"
            :error="$t('partnersSettlements.form.NumberingIsRequired')"
          />
          <formInput
            :title="$t('partnersSettlements.form.PaymentNumber')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'partnersSettlementsEdit'"
            maxlength="255" 
          />
          <subFileInput :title="$t('invoices.form.invoiceFile')"
                     v-model="item.file"
                     :file="item.file"
                     :path="'invoices'"
                     :hasErorr="errors.file"
                     :error="$t('allerts.ChooseASuitableFile')"/>
          <formInput
          :title="$t('salesPayments.form.reference')"
          v-model="item.refrance"
          maxlength="255" />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{
              $t('partnersSettlements.form.PaymentDate')
            }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('partnersSettlements.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('partnersSettlements.form.Now')"
                  v-model="item.date"
                  :disabled=" $user.admin || $user.role.sales_payments_edit_item ? false : true"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"/>
              </div>
            </div>
          </div>
          
          <searchGroupInput
          :type="'project'"
          :values="$database.projects"
          :inputs="[{ 'title':$t('partnersSettlements.form.Project'),'show': 'name'}]"
          v-if="$site.projects_allow"
        />

          <connectionInput
            :disabled="item.project_id == 0"
            :title="$t('partnersSettlements.form.ProjectOutput')"
            :group="$t('partnersSettlements.form.ProjectOutputs')"
            v-model="item.project_item_id"
            :values="$database.projectItems.filter(el => el.project_id == item.project_id)"
            v-if="$site.projects_allow"
          />

          <monyInput
            :title="$t('partnersSettlements.form.SettlementAmount')"
            :disabled="true"
            v-model="item.total"
            :hasErorr="errors.total"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />

          <selectInput
            :title="$t('invoices.form.Situation')"
            v-model="item.status"
            :values="[
              { name: $t('invoices.draft'), value: 0 },
              { name: $t('invoices.approved'), value: 1 }
            ]"
          />

          <dvider
            :title="$t('partnersSettlements.form.PartnerInformation')"
            :description="$t('partnersSettlements.form.PartnerInformationForTheProcess')"
          />
          <productsTable class="col-12" />

          <dvider
            :title="$t('partnersSettlements.form.PaymentNotes')"
            :description="$t('partnersSettlements.form.PaymentNotesDetails')"
          />

          <formTextarea
            :title="$t('partnersSettlements.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea
            :title="$t('partnersSettlements.form.Note')"
            v-model="item.notes"
          />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersSettlementsCreate' && !$parent.stopEdit"
          >
            {{ $t('partnersSettlements.form.addSalesPayment') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersSettlementsEdit' && !$parent.stopEdit"
          >
            {{ $t('partnersSettlements.form.SalesPaymentadjustment') }}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
    <addPaymentMethod v-if="model == 'addPaymentMethod'"/>
    <addPaymentFee v-if="model == 'addPaymentFee'"/>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";

import productsTable from "../elements/add/form/projectsSettlement/productsTable.vue";
import searchGroupInput from "../elements/add/form/searchGroupInput.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/partnersSettlements",
      item: {
        invoice_group: this.$option.partners_settlements_invoice_group_id,
        date: "",
        quotationItems: [],
        cost: 0,
        project_id: 0,
        project: {},
        project_item_id: 0,
        status: 1,
      },
      model: false,
      errors: {
        invoice_group: false,
      },
    };
  },
  mounted() {
    this.$updateDatabase([
      "partners",
      "invoiceGroups",
      "safes",
      "paymentMethods",
      "projects",
      "projectItems"
    ]);

    if (this.$route.name == "partnersSettlementsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    } else {
      if (this.$route.params.id) {
        var safe = this.$database.safes.find((el) => el.id == this.$route.params.id);
        this.item.safe_id = this.$route.params.id;
      }
    }
    if (this.$route.name == "partnersSettlementsCreate") {
      this.addQuotationItem()
      this.item.date = this.$nowDate()
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          var data = response.data
          data.quotationItems.forEach(item => {
            item.percent = item.cost / data.total * 100
          })
          this.item = data;
          if (data.quotationItems.length == 0) {
            this.addQuotationItem()
          }

          this.$parent.stopEdit = false;
          this.item.date = this.$dateFormat(this.item.date, true)
        });
    },
    saveItem() {
      var error = 0;
      if (
        typeof this.item.total === "undefined" ||
        this.item.total == "" ||
        this.item.total == 0
      ) {
        error = 1;
        this.errors.total = true;
      }
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "partnersSettlementsCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == "partnersSettlementsCreate") {
          this.$parent.aletText = this.$t('allerts.partnersSettlementsuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));          
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "partnersSettlementsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText = this.$t('allerts.salesPaymentHasBeenModifiedSuccessfully');
              this.$parent.alertType = "success";
              this.$router.go(-1);
              this.$parent.stopEdit = false;
              return response;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    addQuotationItem() {
      this.item.quotationItems.push({
        id: "",
        partner_name: "",
        partner_id: 0,
        cost: "",
        percent: 0
      });
    },
    changePrice() {
      
      this.item.total = 0;
      this.item.quotationItems.forEach((qitem) => {
        this.item.total += qitem.cost - 0;
      });
      this.item.quotationItems.forEach((qitem) => {
        if (this.item.total != 0) {
          qitem.percent = qitem.cost / this.item.total * 100;
        } else {
          qitem.percent = 0;
        }
      });
    }

  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    loading,

    productsTable,

    VueCtkDateTimePicker,
    searchGroupInput,
    subFileInput,
  },
};
</script>
